* {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  outline: none !important;
  box-shadow: none;
}

.navLink { color: #4B4B4B !important }
.navLink:hover { color: salmon !important }
/* remove dropdown arrow */ #nav-dropdown::after{ display: none }


.sidebarLinks {
  background: white;
  min-width: 17vw;
  height: 92%;
  position: fixed;
  z-index: 999;
  padding: 25px 0px;
  transition: 750ms;
  box-shadow: 40px 0px 40px -40px rgb(0 0 0 / 16%), -40px 0px 40px -40px rgb(0 0 0 / 16%);
}
.sidebarLinks:hover { left: 0 !important }
.sidebarLinks:hover .hideOnHover { transition: 250ms; color: transparent !important; }
.sidebarLinks:not(:hover) .hideOnHover { transition: 1000ms; }
.sidebarLinks > a {
  padding: 12px 7px 12px 0px;
  width: 100%;
  color: black !important;
  transition: 250ms;
}
.sidebarLinks:hover > a {
  padding: 12px 10px 12px 25px;
}
.sidebarLinks > a > span > i,
.sidebarLinks > a > span,
.sidebarLinks > div > a > span {
  font-size: 17px !important;
}
.sidebarLinks > a > span:first-child { min-width: 10%; text-align: center; }
.sidebarLinks > a > span:nth-child(2) { width: 50%; text-align: left;  }
.sidebarLinks > a > span:nth-child(3) { width: 25%; text-align: center; }
.sidebarLinks > a:nth-child(1):hover, .sidebarLinks>div>a:hover,
.sidebarLinks > a:nth-child(3):hover, .sidebarLinks > a:nth-child(4):hover,
.sidebarLinks > a:nth-child(5):hover {
  transition: 500ms;
  background: salmon !important;
  color: white !important;
  cursor: pointer !important;
}
/* change dashboard tab padding bottom .sidebarLinks:hover > a:nth-child(1) { padding-bottom: 5px } */
/* disable select text on navigation sidebar */ .sidebarLinks > a > span {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.sidebarLinks > div > a {
  color: black !important;
  visibility: hidden !important;
  max-height: 0px !important;
  padding: 0px !important;
  transition: 1000ms;
  opacity: 0;
}
.sidebarLinks > a:first-child:hover + div > a, .sidebarLinks > div:hover a {
  opacity: 1;
  visibility: visible !important;
  overflow: hidden;
  max-height: 100px !important;
  padding: 5px 0px 5px 75px !important;
  transition: 500ms;
}
.sidebarLinks > a:first-child:not(:hover) + div > a, .sidebarLinks > div:not(:hover) a {
  transition: 500ms;
}

/* BUTTON */
.ButtonRed {
  background: #ED3F23 !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  width: 120px;
}
.ButtonRed:hover {
  background: #BA321C !important;
}
.ButtonGrey {
  background: white !important;
  color: black !important;
  border: 1px solid lightgrey !important;
  border-radius: 4px !important;
  width: 120px;
}
.ButtonGrey:hover {
  background: lightgrey !important;
}
.ButtonHover {
  background: transparent;
  border-color: transparent;
}
.ButtonHover:hover {
  color: salmon !important;
}

.login {
  background: #ED3F23 !important;
  border-color: transparent !important;
  border-radius: 2px !important;
  height: 45px;
}
.login:hover {
  background: #E75B50 !important;
}
.div-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #F2F2F2;
  width: 100%;
  padding: 10px;
  margin-top: -15px;
  margin-bottom: 20px;
}
.ButtonMargin {
  margin: 0px 5px;
}

/* TABLE */
.table-container {
  width: 90vw;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background: white;
}
td {
  vertical-align: middle;
}

/* INPUT GROUP */
.InputGroup {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0px 20px;
}
.InputGroup > div {
  display: flex;
  width: 500px;
  border: 1px solid lightgrey;
  border-radius: 5px !important;
  margin: 2.5px 0px;
}
.InputGroupText {
  background-color: transparent !important;
  border-color: transparent !important;
}

/* MODAL */
.ModalBody {
  max-height: 310px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ModalBody > div {
  display: flex;
  flex-wrap: wrap;
}
.ModalBody > div > div {
  margin: 0px 0px 20px 30px;
  width: 250px;
}

@media only screen and (hover: none) and (pointer: coarse) {
  thead {
    top: 0px;
  }
  th, td {
    white-space: nowrap;
  }

  .mobileAlignRight {
    text-align: right;
    margin-right: 10px !important;
  }

  .div-buttons {
    padding: 15px 0px;
  }
  .ButtonMargin {
    margin: 5px 5px;
  }
}
/* thead on mobile */
@media (min-width: 0px) and (max-width: 1199px) {
  thead {
    top: 0px;
  }
}

.boxShadow {
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%), 0px 2px 10px 0px rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%), 0px 2px 10px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 16%), 0px 2px 10px 0px rgb(0 0 0 / 12%);
}





/* remove highlight on dropdown menu */ .removeHighlight:active > a { background-color: transparent }
/* remove blue background on dropdown item after click */ .removeBackground:active{ color: black !important; background: white !important }


/* DATEPICKER */
.react-datepicker__input-container, .react-datepicker-wrapper {
  width: 240px !important;
  /* background: red; */
}
.react-datepicker__input-container > input {
  background: white;
  border: 1px solid white;
  padding: 15px 10px;
  border-radius: 3px;
  cursor: pointer;
  width: 200px;
}
.react-datepicker__input-container > input:hover::-webkit-input-placeholder { color: salmon; }
.react-datepicker__input-container > input:hover::placeholder { color: salmon; }


/* InputGroup show password */
.input-group-text { background: transparent !important; }
.input-group-text:hover { cursor: pointer; }



